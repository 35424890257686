import PropTypes from 'prop-types';
import React from 'react';

import Alignment from './Alignment';
import DnaSnpDetection from './DnaSnpDetection';
import IghvHyperMutation from './IghvHyperMutation';
import InputMaterial from './InputMaterial';
import Instrument from './Instrument';
import NonTargetMutations from './NonTargetMutations';
import Pipelines from './Pipelines';

export default function AnalysisTypes(props) {
    return (
        <div>
            <div className="analysis-types-info">
                <Pipelines depConf={props.depConf} pipelineMappings={props.textMappings.pipelines} />
                <InputMaterial
                    inputMaterial={props.depConf.input_material}
                    inputMaterialMappings={props.textMappings.input_material}
                />
                { (props.depConf.is_snp && props.depConf.targeted_variant_name) && (
                    <NonTargetMutations onlyReportTargeted={props.depConf.is_only_report_targeted_mutations} />
                )}
                { (props.depConf.is_dna_snp && props.depConf.dna_snp_detection) && (
                    <DnaSnpDetection
                        mutationType={props.depConf.dna_snp_detection}
                        mutationTypeMappings={props.textMappings.dna_snp_detection}
                    />
                )}

                { (props.depConf.is_preprocessing_only) && (
                    <Alignment preprocessingOnlyAlignment={props.depConf.is_preprocessing_only_run_read_alignment} />
                )}

                { (props.depConf.is_rna_ir && props.depConf.is_ir_profiling_hypermutation) && (
                    <IghvHyperMutation IrProfilingHyperMutation={props.depConf.is_ir_profiling_hypermutation} />
                )}

                { (props.depConf.is_msi) && (
                    <Instrument
                        instrumentType={props.depConf.instrument_type} 
                        instrumentMappings={props.textMappings.instrument_type}
                    />
                )}
            </div>
        </div>
    );
}

AnalysisTypes.propTypes = {
    depConf: PropTypes.object,
    textMappings: PropTypes.object.isRequired,
};

AnalysisTypes.defaultProps = {
    depConf: null,
};
