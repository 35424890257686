import React from 'react';
import PropTypes from 'prop-types';

export default function NonTargetMutations(props) {
    return (
        <>
            <div className="analysis-type-info-label">Include Non-Targeted Mutations:</div>
            <div>{props.onlyReportTargeted ? 'No' : 'Yes'}</div>
        </>
    );
}

NonTargetMutations.propTypes = {
    onlyReportTargeted: PropTypes.bool.isRequired,
};
