import React from 'react';
import PropTypes from 'prop-types';

import getFromDict from '@CoreModules/getFromDict';

export default function DnaSnpDetection(props) {
    const mapping = getFromDict(props.mutationTypeMappings, props.mutationType, null);
    const displayValue = mapping ? mapping.display : '';

    return (
        <>
            <div className="analysis-type-info-label">Mutation Detection Type:</div>
            <div>{displayValue}</div>
        </>
    );
}

DnaSnpDetection.propTypes = {
    mutationType: PropTypes.string.isRequired,
    mutationTypeMappings: PropTypes.object.isRequired,
};
