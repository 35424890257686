/**
 * Python style get function for getting javascript dictionary keys with a default return
 * @param {Object} object
 * @param {string} key
 * @param {string} default_value
 * @returns value of the dictionary key
 */
export default function getFromDict(object, key, defaultValue) {
    let result = object[key];
    return (typeof result !== 'undefined') ? result : defaultValue;
}
