import React from 'react';
import PropTypes from 'prop-types';

export default function IghvHyperMutation(props) {
    return (
        <>
            <div className="analysis-type-info-label">IGHV Hypermutation:</div>
            <div>{props.IrProfilingHyperMutation ? 'On' : 'Off'}</div>
        </>
    );
}

IghvHyperMutation.propTypes = {
    IrProfilingHyperMutation: PropTypes.bool.isRequired,
};
