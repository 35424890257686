import React from 'react';
import PropTypes from 'prop-types';
import getFromDict from '@CoreModules/getFromDict';

export default function Instrument(props) {
    const mapping = getFromDict(props.instrumentMappings, props.instrumentType, null);
    const displayValue = mapping ? mapping.display : '';

    return (
        <>
            <div className="analysis-type-info-label">Instrument:</div>
            <div>{displayValue}</div>
        </>
    );
}

Instrument.propTypes = {
    instrumentMappings: PropTypes.object.isRequired,
    instrumentType: PropTypes.string.isRequired,
};
