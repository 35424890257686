import React, { Component } from 'react';

import PropTypes from 'prop-types';

export default class Pipelines extends Component {
    constructor(props) {
        const analysisPipelines = Pipelines.getAnalysisTypes(props.depConf);
        super(props);
        this.state = {
            pipelineMappings: props.pipelineMappings,
            depConf: props.depConf,
            analysisTypes: analysisPipelines,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.depConf !== props.depConf) {
            return {
                analysisTypes: Pipelines.getAnalysisTypes(props.depConf),
            };
        }
        return null;
    }

    /**
     * Function to get the turned on analysis types from a dependency configurable object
     * @param {object} depConf
     * @returns {list}
     */
    static getAnalysisTypes(depConf) {
        let analysisTypeAttributes = Object.entries(depConf).filter(
            (attribute) => attribute[0].match('^is_') && attribute[1]);
        return analysisTypeAttributes.map((attribute) => attribute[0]);
    }

    render() {
        return (
            <>
                <div className="analysis-type-info-label">Pipeline(s):</div>
                <div>
                    {Object.keys(this.state.pipelineMappings).map((analysisType) => {
                        if (
                            (this.state.analysisTypes.indexOf(analysisType) !== -1)
                            && this.state.depConf[analysisType]) {
                            return (
                                <div
                                    key={analysisType}
                                >
                                    {this.state.pipelineMappings[analysisType].display}
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            </>
        );
    }
}

Pipelines.propTypes = {
    pipelineMappings: PropTypes.object.isRequired,
    depConf: PropTypes.object.isRequired,
};
