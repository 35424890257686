import React from 'react';
import PropTypes from 'prop-types';
import getFromDict from '@CoreModules/getFromDict';

export default function InputMaterial(props) {
    const mapping = getFromDict(props.inputMaterialMappings, props.inputMaterial, null);
    const displayValue = mapping ? mapping.display : '';

    return (
        <>
            <div className="analysis-type-info-label">Input Material:</div>
            <div>{displayValue}</div>
        </>
    );
}

InputMaterial.propTypes = {
    inputMaterialMappings: PropTypes.object.isRequired,
    inputMaterial: PropTypes.string.isRequired,
};
